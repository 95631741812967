import http from "../../services/api";
import { logout } from "../../services/localStorage";
import { uploadArchive } from "../../services/upload-archives";
import { PropsCreateSubZone } from "./type";

export const CreateSubZoneRequest = async (
  body: PropsCreateSubZone,
  file?: File
) => {
  if (file) {
    return await uploadArchive(file, "/direct/sub-zone", body, "POST");
  }
  return await http
    .post("/direct/sub-zone", body)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err.response.data.message);
      throw err;
    });
};

export const BffSubZoneRequest = async (id: string | undefined) => {
  return await http
    .get(`/bff/sub-zone-bff`, {
      params: {
        subZoneId: id,
      },
    })
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      throw err;
    });
};

export const UpdateSubZoneRequest = async (
  body: any,
  id: string | undefined
) => {
  return await http
    .put(`/direct/sub-zone/${id}`, body)
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err.response.data.message);
      throw err;
    });
};

export const ExitUserSubZone = async (idSubzone: string) => {
  return await http
    .delete(`/bff/sub-zone-user-bff/exit`, { params: { subZoneId: idSubzone } })
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err.response.data.message);
      throw err;
    });
};
