// import { Container } from './styles';
import { Badge } from "primereact/badge";
import { useLocation, useNavigate } from "react-router-dom";
import { Tooltip } from "../../../ludz.one_ui";
import { idZone } from "../../../services/localStorage";
import { Avatar, Container } from "./style";
import { useMediaQuery } from "@mui/material";
;

const CardZona = ({ zona }: any) => {
  const history = useNavigate();
  const location = useLocation();
  const matches = useMediaQuery("(min-width: 2000px)")
  const matchesMobile = useMediaQuery("(max-width: 520px)");
  const matchesTablet = useMediaQuery("(max-width: 920px)")



  const DecimalBR = () => {
    let numero = zona?.price;

    return parseFloat(numero.toFixed(2)).toLocaleString('pt-BR', { minimumFractionDigits: 2 });
  }

  const NameRoomFormat = (name: string) => {
    if (name.length > 30) {
      return name.substring(0, 20) + "...";
    }
    return name;
  };

  const DescriptionZona = (description: string) => {
    if (description.length > 50) {
      return description.substring(0, 50) + "...";
    } else {
      return description;
    }
  };

  const months = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  const formatDate = () => {
    if (zona?.zone?.createdAt || zona?.createdAt) {
      const date = new Date(zona?.zone?.createdAt ?? zona?.createdAt);

      var day: any = date.getDate();

      if (day < 10) {
        day = "0" + day;
      }

      var postDate = day + " " + months[date.getMonth()].substring(0, 3);
      return postDate;
    } else {
      return "Não possui data";
    }
  };
  const description = DescriptionZona(
    zona?.zone?.description ?? zona?.description
  );
  const strippedText = description.replace(/<[^>]*>/g, "");

  return (
    <div className="p-1 lg:p-2">
      <Container
        className="w-full border-1 surface-border cursor-pointer z-index"
        style={{ borderRadius: "10px" }}
        onClick={() => {
          if (location.pathname === "/home") {
            history(`/zona/${zona?.zone?.id ?? zona?.id}/painel`);
            idZone(zona?.zone?.id ?? zona?.id);
          }
          if (location.pathname === "/home/zonaspublicas") {
            history(`/comprazonas/${zona?.id}`);
            idZone(zona?.zone?.id ?? zona?.id);
          }
        }}
      >
        <div className="relative">
          <img
            src={zona?.zone?.aws_2?.url ?? zona?.aws_2?.url}
            className="w-full"
            alt={zona?.zone?.description ?? zona?.description}
            style={{ height: matchesTablet ? '150px' : '180px', borderRadius: "10px", maxHeight: matches ? 250 : 200, borderBottom: '1px solid #d4d4d4' }}
          />
          <Avatar
            id="avatar"
            src={zona?.zone?.aws?.url ?? zona?.aws?.url}
            className="flex absolute w-4rem h-4rem border-1 border-gray-300"
            style={{ bottom: "-1.5rem", right: "1.2rem" }}
            alt={zona?.zone?.description ?? zona?.description}
          />
        </div>
        <div className="p-3 mt-2">
          <div className="mb-3">
            <h4 className="text-900 font-semibold text-md md:text-xl">
              {NameRoomFormat(zona?.zone?.name ?? zona?.name)}
            </h4>
            {zona?.user_owner ? (
              <i className="text-sm">{`Autor: ${zona?.user_owner?.username}`}</i>
            ) : null}
          </div>

          <p
            className="text-700 text-sm md:text-lg mt-0 mb-5"
            dangerouslySetInnerHTML={{
              __html: DescriptionZona(strippedText),
            }}
            style={{ wordBreak: "break-all" }}
          />
          {zona?.zone || zona ? (
            <div
              className="icon-container flex flex-wrap gap-2 align-items-center justify-content-between"
              style={{ bottom: "16px" }}
            >
              <span className="flex align-items-center text-900 text-sm md:text-lg">
                {zona?.zone?.qntSubZones || zona?.price === undefined ? (
                  <>
                    <i className="pi pi-sitemap mr-2"></i>
                   <Tooltip title="Subzonas">
                   <span className="font-semibold">
                      {zona?.zone?.qntSubZones}
                    </span>
                   </Tooltip>
                  </>
                ) : 
                <Tooltip title="Preço">
                  <>
                  <Badge value={`R$ ${DecimalBR()}`} severity="success"></Badge>
                  </>
                </Tooltip>
                }
              </span>
              <span className="flex align-items-center text-900 text-sm md:text-lg">
                <i className="pi pi-users mr-2"></i>
                <Tooltip title="Membros">
                <span className="font-semibold">
                  {zona?.zone?.qntMembers ?? zona?._count?.zoneMembers}
                </span>
                </Tooltip>
              </span>
              <span className="flex align-items-center text-900 text-sm md:text-lg">
                <i className="pi pi-clock mr-2"></i>
                <Tooltip title="Data de criação">
                <span className="font-semibold mr-1">{formatDate()}</span>
                </Tooltip>
              </span>
            </div>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default CardZona;
