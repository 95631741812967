import { Formik } from "formik";
import { useContext, useState } from "react";
import PostSubonaProvider from "../../../contexts/Subzone/MuralSubzone/context";
import { SubzonaContext } from "../../../contexts/Subzone/Subzone/context";
import { SubzonaContextType } from "../../../contexts/Subzone/Subzone/type";
import { SubzonaInfoContext } from "../../../contexts/Subzone/SubzoneInfo/context";
import { SubZonaInfoContextType } from "../../../contexts/Subzone/SubzoneInfo/type";
import { ZonaContext } from "../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../contexts/Zone/Zone/type";
import { Avatar, EditorText, Icon, Input, Tooltip } from "../../../ludz.one_ui";
import ButtonPrime from "../../../ludz.one_ui/Buttons/ButtonPrime";
import ConfirmationDialog from "../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import EditAvatar from "../../../ludz.one_ui/EditAvatar";
import { Padding } from "../../../ludz.one_ui/styles/styled-components";
import { getId } from "../../../services/localStorage";
import { Column, Row } from "../../../styles/globalStyles";
import { Container } from "../../style";
import SubzonePainel from "./SubzonePainel";
import * as C from "./style";
import { useMediaQuery } from "@mui/material";
import { Toast } from "primereact/toast";

const SubzoneInfo = () => {
  // const { zone } = useContext(ZonaContext) as ZonaContextType;

  const { subzone, HandleExitMember } = useContext(
    SubzonaContext
  ) as SubzonaContextType;

  const { adminorowner } = useContext(ZonaContext) as ZonaContextType;
  const [visible, setVisible] = useState(false);
  const [visibleExit, setvisibleExit] = useState(false);
  const matchesMobileSmall = useMediaQuery("(max-width: 360px)");
  const [fileAvatar, setFileAvatar] = useState<File | undefined>();
  const [removeAvatar, setremoveAvatar] = useState<boolean | undefined>(false);

  const {
    HandleSaveInfo,
    HandleSaveAvatarInfo,
    HandleRemoveAvatarInfo,
    HandleDeleteSubZone,
    isediting,
    setIsEditing,
    subzonename,
    setSubZoneName,
    subzonedescription,
    setSubZoneDescription,
    initialValues,
    toast,
  } = useContext(SubzonaInfoContext) as SubZonaInfoContextType;

  const edit = () => setIsEditing(true);

  const editorText = (e: any, setFieldValue: any) => {
    if (setFieldValue !== "") {
      setFieldValue("description", e.editor.getHTML());
    } else {
      setFieldValue("description", subzonedescription);
    }
  };

  const cancelEdit = () => {
    setIsEditing(false);
    setSubZoneName(subzone?.name);
    setSubZoneDescription(subzone?.description);
  };

  const remove = () => {
    HandleRemoveAvatarInfo();
  };

  const NameRoomFormat = (name: any) => {
    if (name.length > 30) {
      return name.substring(0, 30) + "...";
    }
    return name;
  };

  //console.log(subzone)
  return (
    <Container>
      <Toast ref={toast} />
      {subzone && (
        <Column>
          {adminorowner || getId() === subzone.user_owner.id.toString() ? (
            <C.Container>
              <Formik
                initialValues={initialValues}
                onSubmit={(value) => {
                  HandleSaveInfo(value);
                  if (fileAvatar)
                    HandleSaveAvatarInfo(fileAvatar, setFileAvatar);
                  if (removeAvatar) remove();
                }}
              >
                {({
                  values,
                  handleChange,
                  setFieldValue,
                  handleSubmit,
                  errors,
                  touched,
                }) => {
                  return (
                    <>
                      <form onSubmit={handleSubmit} className="card">
                        <Row style={{ marginBottom: "20px" }}>
                          <C.EditAvatar>
                            <EditAvatar
                              isediting={isediting}
                              initialAvatarUrl={subzone?.aws?.url}
                              setImage={setFileAvatar}
                            />
                            {isediting && (
                              <>
                                {removeAvatar ? (
                                  <h3>Imagem será removida</h3>
                                ) : (
                                  <Tooltip
                                    title="Remover"
                                    placement="bottom"
                                    arrow
                                  >
                                    <i
                                      onClick={() => setremoveAvatar(true)}
                                      className="pi pi-trash"
                                      style={{
                                        marginTop: matchesMobileSmall
                                          ? "-16%"
                                          : "5px",
                                        marginRight: matchesMobileSmall
                                          ? "14px"
                                          : "0",
                                        color: "orange",
                                        fontSize: "1rem",
                                      }}
                                    ></i>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </C.EditAvatar>
                          {!matchesMobileSmall && <Padding />}
                          <Column
                            id="center"
                            style={{
                              marginLeft: matchesMobileSmall ? "-4%" : "0",
                            }}
                          >
                            {isediting ? (
                              <Input
                                name="name"
                                defaultValue={subzone.name}
                                value={values.name}
                                onChange={handleChange}
                                placeholder={subzone.name}
                                className={
                                  errors.name && touched.name ? "p-invalid" : ""
                                }
                              />
                            ) : (
                              <Tooltip title={subzone.name}>
                                <h2
                                  className="edit-in-place"
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      NameRoomFormat(subzone.name) ||
                                      "Escreva Alguma coisa",
                                  }}
                                />
                              </Tooltip>
                            )}
                            {errors.name && touched.name && (
                              <div style={{ color: "red" }}>{errors.name}</div>
                            )}
                            <C.AreaInfo>
                              <span>Autor: {subzone.user_owner.username}</span>
                              <span style={{ marginRight: "10px" }}>
                                Membros: {subzone.members.length}
                              </span>
                            </C.AreaInfo>
                          </Column>
                          {!isediting && (
                            <C.AreaOptions>
                              <Tooltip
                                title="Editar subzona"
                                placement="bottom"
                                arrow
                              >
                                <C.IconEdit color="green">
                                  <Icon
                                    style={{
                                      fontSize: "1.5rem",
                                    }}
                                    icon="pi pi-pencil"
                                    onClick={edit}
                                  />
                                </C.IconEdit>
                              </Tooltip>
                              <Tooltip
                                title="Deletar subzona"
                                placement="bottom"
                                arrow
                              >
                                <C.IconEdit color="red">
                                  <Icon
                                    onClick={() => setVisible(!visible)}
                                    icon="pi pi-trash"
                                  ></Icon>
                                </C.IconEdit>
                              </Tooltip>
                              <Tooltip title="Sair da subzona">
                                <C.IconEdit color="red">
                                  <Icon
                                    icon="pi pi-sign-out"
                                    onClick={() => setvisibleExit(true)}
                                  />
                                </C.IconEdit>
                              </Tooltip>
                            </C.AreaOptions>
                          )}
                        </Row>
                        {isediting && (
                          <EditorText
                            value={subzone.description}
                            name="description"
                            onTextChange={(e: any) =>
                              editorText(e, setFieldValue)
                            }
                          />
                        )}
                        <Padding />
                        {errors.description && touched.description && (
                          <div style={{ color: "red" }}>
                            {errors.description}
                          </div>
                        )}
                        <div>
                          {isediting && (
                            <C.AreaButtons>
                              <ButtonPrime
                                onClick={cancelEdit}
                                title="Cancelar"
                                severity="danger"
                                style={{ marginRight: "6px" }}
                              ></ButtonPrime>
                              <ButtonPrime
                                title="Salvar"
                                type="submit"
                              ></ButtonPrime>
                            </C.AreaButtons>
                          )}
                        </div>
                        {/* Arrea de upload e exibição de arquivos */}
                      </form>
                    </>
                  );
                }}
              </Formik>
              <PostSubonaProvider>
                <SubzonePainel subzone={subzone} />
              </PostSubonaProvider>
            </C.Container>
          ) : (
            <C.Container>
              {/* Usuários Comuns */}
              <Row className="card">
                <C.EditAvatar>
                  <Avatar
                    shape="circle"
                    size="xlarge"
                    label={
                      !subzone.aws.url
                        ? subzone.name?.substring(0, 1)
                        : undefined
                    }
                    img={subzone.aws.url ? subzone.aws.url : undefined}
                  />
                </C.EditAvatar>
                <Padding />
                <Column id="center">
                  <Tooltip title={subzonename}>
                    <h2>{NameRoomFormat(subzonename)}</h2>
                  </Tooltip>
                  <C.AreaInfo>
                    <span style={{ marginRight: "10px" }}>
                      Autor: {subzone.user_owner.username}
                    </span>
                    <Padding />
                    <span>Membros: {subzone.members.length}</span>
                  </C.AreaInfo>
                </Column>
                <Tooltip title="Sair da subzona">
                  <C.IconEdit color="red">
                    <Icon
                      icon="pi pi-sign-out"
                      onClick={() => setvisibleExit(true)}
                    />
                  </C.IconEdit>
                </Tooltip>
              </Row>
              <PostSubonaProvider>
                <SubzonePainel subzone={subzone} />
              </PostSubonaProvider>
            </C.Container>
          )}
        </Column>
      )}
      <ConfirmationDialog
        accept={() => HandleDeleteSubZone(subzone!.id.toString())}
        visible={visible}
        reject={() => setVisible(true)}
        message="Deseja Excluir?"
        onHide={() => setVisible(false)}
      />
      <ConfirmationDialog
        accept={() => HandleExitMember(getId()!, subzone!.id.toString())}
        visible={visibleExit}
        reject={() => setvisibleExit(true)}
        message="Deseja Sair?"
        onHide={() => setvisibleExit(false)}
      />
    </Container>
  );
};

export default SubzoneInfo;
