import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useFetchSubzoneBff } from "./query";
import {
  CreateSubZoneRequest,
  UpdateSubZoneRequest,
} from "./request";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../components/Alert";
import { PropsCreateSubZone } from "./type";
import { DeleteSubZone } from "./Information/request";

export const CreateSubZoneController = ({ refetch }: any) => {
  const history = useNavigate();
  const CreateSubZoneMutation = useMutation(
    ({ body, file }: { body: PropsCreateSubZone; file?: File }) =>
      CreateSubZoneRequest(body, file),
    {
      onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
      },
      onError: (error: any) => {
        //   setError(error.response.data.message);
        //   setLoading(false);
        console.log(error);
      },
      onSuccess: (result, variables, context) => {
        refetch();
        console.log(result, variables, context);
        history(`/zona/${variables?.body?.zone}/status-subzona`);
      },
    }
  );

  return {
    CreateSubZoneMutation,
  };
};

export const DeleteSubZoneController = (
  { refetch }: any,
  {
    toast,
  }: {
    toast?: RefObject<Toast>;
  }
) => {
  const DeleteSubZoneMutation = useMutation(
    (id: string | null) => DeleteSubZone(id),
    {
      onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
      },
      onError: (error: any) => {
        Alert({
          summary: "Erro",
          detail: "Aconteceu algum problema, tente novamente",
          severity: "error",
          toast: toast,
        }).show();
        console.log(error);
      },
      onSuccess: (result, variables, context) => {
        Alert({
          detail: "SubZona removida!",
          severity: "success",
          summary: "Sucesso",
          toast: toast,
        }).show();
        refetch();
      },
    }
  );

  return {
    DeleteSubZoneMutation,
  };
};

export const UpdateSubZoneController = (
  { refetch }: any,
  {
    toast,
  }: {
    toast?: RefObject<Toast>;
  }
) => {
  interface PropsUpdate {
    id: string;
    body: any;
  }
  const UpdateSubZoneMutation = useMutation(
    ({ body, id }: PropsUpdate) => UpdateSubZoneRequest(body, id),
    {
      onMutate: () => {
        //   setLoading(true);
        //   queryClient.refetchQueries()
        refetch();
      },
      onError: (error: any) => {
        Alert({
          summary: "Erro",
          detail: "Aconteceu algum problema, tente novamente",
          severity: "error",
          toast: toast,
        }).show();
        console.log(error);
      },
      onSuccess: (result, variables, context) => {
        Alert({
          detail: "SubZona atualizada!",
          severity: "success",
          summary: "Sucesso",
          toast: toast,
        }).show();
        refetch();
      },
    }
  );

  return {
    UpdateSubZoneMutation,
  };
};

export const BffSubzoneController = (id: string) => {
  const { data, refetch } = useFetchSubzoneBff(id!);

  return {
    data,
    refetch,
  };
};
