import axios from "axios";
import http from "../../services/api";
import { logout } from "../../services/localStorage";

export const getChannelCompletRequest = async (id: number) => {
  return await http
    .get("/bff/channel-bff/get-user-channel", { params: { channelId: id } })
    .then((response) => response.data)
    .catch((err) => {
      if (err.response.status === 401) {
        logout();
        window.location.reload();
      }
      alert(err.response.data.message);
      throw err;
    });
};


export const getChannelVideosYoutubeRequest = async (id: string) => {
  return await http
      .get('/bff/youtube-bff/'+ id)
      .then((response) => response.data)
      .catch((err) => {
          if (err.response.status === 401) {
              logout();
              window.location.reload();
          }
          throw err;
      });
};