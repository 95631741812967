import { useMutation } from "react-query";
import {
  DeleteSessionWatchedZoneRequest,
  SessionZoneWatchedRequest,
} from "./request";
import queryClient from "../../../../services/react-query";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../../components/Alert";

export const SessionWatchedZoneController = ( {
  toast,
}: {
  toast?: RefObject<Toast>;
} ) => {
  const SessionWatchedZoneMutation = useMutation(
    (data: { sessionContentId: number }) => SessionZoneWatchedRequest(data),
    {
      onMutate: () => {},
      onError: (error: any) => {
        Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
        console.log(error);
      },
      onSuccess: (result: any) => {
        Alert({
          detail: "Conteúdo inserido!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        queryClient.refetchQueries("UseZoneSessionWatchedBff");
      },
    }
  );

  const DeleteSessionWatchedContentsZoneMutation = useMutation(
    (id: string) => DeleteSessionWatchedZoneRequest(id),
    {
      onMutate: () => {},
      onError: (error: any) => {
        Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
        console.log(error);
      },
      onSuccess: (result: any) => {
        Alert({
          detail: "Conteúdo excluido!",
          severity: "success",
          summary: "Sucesso",
          toast: toast
        }).show();
        queryClient.refetchQueries("UseZoneSessionWatchedBff");
      },
    }
  );

  return {
    SessionWatchedZoneMutation,
    DeleteSessionWatchedContentsZoneMutation,
  };
};
