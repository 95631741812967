import { useContext } from "react";

import { useMediaQuery } from "@mui/material";
import { SessionZoneState } from "../../../contexts/Zone/SessionZone/state";
import { StateZonesPublic } from "../../../contexts/Zone/ZonePublic/state";
import {
  Accordion,
  Avatar,
  Button,
  Progress,
  Tooltip,
} from "../../../ludz.one_ui";
import LudzLoading from "../../../ludz.one_ui/Loading";
import { Column, Padding } from "../../../ludz.one_ui/styles/styled-components";
import { Row } from "../../../styles/globalStyles";
import { Container } from "../../style";
import HeaderAccordion from "./HeaderAccordion";
import * as C from "./style";
import { PrivateRoutesContext } from "../../../contexts/PrivateRoutes/context";
import { PrivateRoutesContextType } from "../../../contexts/PrivateRoutes/types";
import { useNavigate } from "react-router-dom";
import { ZonePublicContext } from "../../../contexts/Zone/UniqueZonePublic/context";
import { ZonePublicContextType } from "../../../contexts/Zone/UniqueZonePublic/type";
import { AreaSobre } from "../Zone/ZoneInfo/ZonePainel/style";
import ViewHTML from "../../../components/ViewHTML";
import BodySession from "./BodySession";
import LudzScrollTop from "../../../ludz.one_ui/LudzScrollTop";
import { TypeTagZone } from "../../../ludzoneSDK/Zone/type";
import ChipTags from "./ChipTags";

const BuyZoneScreen = () => {
  const matchesMobileSmall = useMediaQuery("(max-width: 560px)");
  const history = useNavigate();

  const { userProvider } = useContext(
    PrivateRoutesContext
  ) as PrivateRoutesContextType;

  const { zonepublic } = useContext(ZonePublicContext) as ZonePublicContextType;

  const confirmBuyZonePublic = () => {
    if (
      userProvider?.zoneUser?.some((item) => item?.zone.id === zonepublic?.id)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const { handleEntryZone } = StateZonesPublic();
  const { session } = SessionZoneState();


  const idzone = {
    zoneId: zonepublic?.id.toString(),
  };

  let names = `${zonepublic?.name}`;
  var initialName = names.substring(0, 2);

  const NameRoomFormat = (name: any) => {
    if (name.length > 30 && matchesMobileSmall) {
      return name.substring(0, 30) + "...";
    }
    return name;
  };

  const VerifyMember = () => {
    const findMember = zonepublic?.zoneMembers?.find(
      (props) => props.user_fk === userProvider?.id
    );
    return findMember;
  };

  return (
    <Container>
      {zonepublic ? (
        <Column>
          <C.Container>
            <div className="card">
              <Column style={{ alignItems: "center", gap: "8px" }}>
                {zonepublic.aws_2.url && (
                  <C.ImageCover
                    src={zonepublic.aws_2.url}
                    alt={zonepublic.name}
                  />
                )}
              </Column>
              <Padding padding="10px"/>
              <Row
                id="space-between"
                style={{ flexDirection: matchesMobileSmall ? "column" : "row" }}
              >
                <Column>
                  <Row
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Avatar
                      shape="circle"
                      size={matchesMobileSmall ? "large" : "xlarge"}
                      label={!zonepublic.aws.url ? initialName : undefined}
                      img={zonepublic.aws.url ? zonepublic.aws.url : undefined}
                      style={{
                        border: "1px solid #CCC",
                        marginRight: matchesMobileSmall ? "0" : "6px", // Remove margem lateral em telas pequenas
                        maxWidth: matchesMobileSmall ? "46px" : "62px", // Defina um tamanho máximo para telas pequenas
                        width: "100%", // Garanta que o avatar ocupe todo o espaço disponível
                      }}
                    />
                    <Column>
                      <Tooltip title={zonepublic.name}>
                        <h2
                          className="edit-in-place"
                          dangerouslySetInnerHTML={{
                            __html:
                              NameRoomFormat(zonepublic.name) ||
                              "Escreva Alguma coisa",
                          }}
                        />
                      </Tooltip>
                    </Column>
                  </Row>
                  {zonepublic.tags.length > 0 && (
                    <>
                      <h5>Tags da zona</h5>
                      <div className="flex justify-center items-center flex-wrap gap-2 mt-2 mb-2">
                        {zonepublic?.tags?.map(
                          (item: TypeTagZone, index: number) => (
                            <ChipTags tags={item} />
                          )
                        )}
                      </div>
                    </>
                  )}
                </Column>

                {!confirmBuyZonePublic() && (
                  <Column
                    style={{
                      alignItems: "center",

                      padding: "20px",
                      border: "1px solid #e0e0e0",
                      borderRadius: "8px",
                      boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
                      backgroundColor: "#fff",
                      maxWidth: "320px",
                    }}
                  >
                    <h3
                      style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        margin: "10px 0",
                        color: "#333",
                      }}
                    >
                      R${" "}
                      {zonepublic?.price
                        ? zonepublic?.price.toFixed(2)
                        : "0.00"}
                    </h3>
                    <span
                      style={{
                        color: "#888",
                        fontSize: "14px",
                        marginBottom: "15px",
                      }}
                    >
                      Oportunidade inperdivel!
                    </span>
                    <Button
                      title="Entrar na zona"
                      onClick={() => {
                        if (VerifyMember()) {
                          history(`/zona/${zonepublic.id!}/painel`);
                        }
                        if (!VerifyMember()) {
                          handleEntryZone(idzone);
                        }
                      }}
                      style={{
                        width: "100%",
                        padding: "10px",
                        fontSize: "18px",
                        fontWeight: "bold",
                        backgroundColor: "#4CAF50", // Cor roxa do botão similar à da Udemy
                        color: "#fff",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                    />
                    <span
                      style={{
                        fontSize: "12px",
                        color: "#888",
                        marginTop: "8px",
                        textAlign: "center",
                      }}
                    >
                     Aproveite essa oferta!
                    </span>
                  </Column>
                )}
              </Row>
            </div>
            <div className="grid" style={{ marginTop: "-20px" }}>
              <div className="col-12 pb-0">
                <div className="grid">
                  <div className="col-12 sm:col">
                    <div
                      className="card text-0 flex justify-content-between  h-full"
                      style={{
                        backgroundColor: "var(--primary-color)",
                      }}
                    >
                      <div className="overview-info">
                        <h5 className="m-0 mb-1 text-0">Membros</h5>
                        <h1 className="m-0 text-0">
                          {zonepublic?.zoneMembers?.length}
                        </h1>
                      </div>
                      <i className="pi pi-users text-3xl"></i>
                    </div>
                  </div>
                  <div className="col-12 sm:col">
                    <div className="card overview-box blue text-white flex justify-content-between h-full">
                      <div className="overview-info">
                        <h5 className="m-0 mb-1 text-white">Subzonas</h5>
                        <h1 className="m-0 text-white">
                          {zonepublic?.subZones?.length}
                        </h1>
                      </div>
                      <i className="pi pi-sitemap text-3xl"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <AreaSobre className="card device-status">
                  <div className="grid">
                    <div className="col-12 xl:col-9">
                      <div className="card-header">
                        <div className="card-title">
                          <h5>Sobre</h5>
                        </div>
                      </div>
                      <ViewHTML item={zonepublic?.description.toString()} />
                    </div>
                  </div>
                </AreaSobre>
              </div>
            </div>

            {session ? (
              <div className="card" style={{ marginTop: "10px" }}>
                <h5 className="mb-3">Conteudo</h5>
                {session.length !== 0 && (
                  <Accordion
                    body={BodySession}
                    header={HeaderAccordion}
                    data={session}
                  />
                )}
              </div>
            ) : (
              <Progress />
            )}
          </C.Container>
        </Column>
      ) : (
        <LudzLoading />
      )}
      <LudzScrollTop style={{ right: "0.5%", padding: "15px" }} />
    </Container>
  );
};

export default BuyZoneScreen;
