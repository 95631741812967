
import { useMediaQuery } from '@mui/material';
import { Dialog } from 'primereact/dialog';
import { CSSProperties } from 'react';
import { Tooltip } from '../..';

interface PropsModal {
  visible?: boolean,
  onOpen: any,
  title?: any,
  children?: any
  style?:CSSProperties | undefined
}

const Modal = ({ visible, onOpen, title, children, style }: PropsModal) => {

  const matches = useMediaQuery('(max-width:600px)');

  const Header = () => {

    return (
      <>
      {title ? 
      <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
      <h4>{title}</h4>
      <Tooltip title='Pressione ESC para fechar!'>
      <span style={{ marginRight: '6px', fontSize: '0.6rem', color: 'gray' }}>(ESC)</span>
      </Tooltip>
    </div>
      : 
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
       <Tooltip title='Pressione ESC para fechar!'>
      <span style={{ marginRight: '6px', fontSize: '0.6rem', color: 'gray' }}>(ESC)</span>
      </Tooltip>
      </div>
      }
      </>
    )
  }

  return (
    <Dialog header={Header} visible={visible}  style={style ?? { width: matches ? '80vw' : '50vw' }} onHide={onOpen}>
      {children}
    </Dialog>
  );
};

export default Modal;
