import { useMutation } from "react-query";
import { useFetchZoneSessionsContents } from "./query";
import { EditTextSessionContentZoneRequest, SessionContentsZoneRequest } from "./request";
import queryClient from "../../../../services/react-query";
import { PropsSessionContentText } from "./type";
import { RefObject } from "react";
import { Toast } from "primereact/toast";
import { Alert } from "../../../../components/Alert";

export const SessionContentsZoneController = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  },id?: number) => {

    const { data: sessionsContentsRequest, } = useFetchZoneSessionsContents(id)

    const SessionContentsZoneMutation = useMutation(({data, file}:{data: PropsSessionContentText, file?: File}) => SessionContentsZoneRequest(data, file), {
        onMutate: () => {
        },
        onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result: any) => {
            Alert({
                detail: "Conteúdo criado!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseZoneSessionBff");
        },
    });

    return {
        SessionContentsZoneMutation, sessionsContentsRequest
    }
}

interface PropsEditSessionContentsText {
    id: number,
    body: { textContent?: string | null, title?: string }
}

export const EditSessionContentsText = ({
    toast,
  }: {
    toast?: RefObject<Toast>;
  }) => {

    const EditSessionContentsZoneMutation = useMutation(({ id, body }: PropsEditSessionContentsText) => EditTextSessionContentZoneRequest(body, id), {
        onMutate: () => {
        },
        onError: (error: any) => {
            Alert({ summary: "Erro", detail: "Aconteceu algum problema, tente novamente", severity: "error", toast: toast }).show();
            console.log(error)
        },
        onSuccess: (result: any) => {
            Alert({
                detail: "Conteúdo atualizado!",
                severity: "success",
                summary: "Sucesso",
                toast: toast
              }).show();
            queryClient.refetchQueries("UseZoneSessionBff");
            queryClient.refetchQueries("UseZoneSessionContents");
            
        },
    });

    return {
        EditSessionContentsZoneMutation
    }
}