import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Dropdown } from "primereact/dropdown";
import { Image } from "primereact/image";
import { InputText } from "primereact/inputtext";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ViewHTML from "../../../../../components/ViewHTML";
import { StatusSubzonaContext } from "../../../../../contexts/Zone/SubzoneStatus/contex";
import { StatusSubzonaContextType } from "../../../../../contexts/Zone/SubzoneStatus/types";
import { ZonaContext } from "../../../../../contexts/Zone/Zone/context";
import { ZonaContextType } from "../../../../../contexts/Zone/Zone/type";
import { Button, Tooltip } from "../../../../../ludz.one_ui";
import ConfirmationDialog from "../../../../../ludz.one_ui/Diaolog/ConfrimationDIalog/indes";
import { Padding } from "../../../../../ludz.one_ui/styles/styled-components";
import { Row } from "../../../../../styles/globalStyles";
import SkeletonSubzona from "../Components/SkeletonSubzona";
import { AreaDescriptionList, AreaHeaderList, Arq, CardGrid } from "./style";
import { Toast } from "primereact/toast";

// import { Container } from './styles';

const DataViewSubzone = () => {
  const { id } = useParams();
  const history = useNavigate();

  const {
    setStatusSelect,
    status,
    deleteSubzona,
    subzone,
    handleAvaliate,
    toast
  } = useContext(StatusSubzonaContext) as StatusSubzonaContextType;
  const { adminorowner } = useContext(ZonaContext) as ZonaContextType;


  const [visible, setVisible] = useState(false);
  const [dataViewValue, setDataViewValue] = useState<any[]>(subzone);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filteredValue, setFilteredValue] = useState<any[] | null>(subzone);
  const [layout, setLayout] = useState<
    "grid" | "list" | (string & Record<string, unknown>)
  >("list");
  const [sortField, ] = useState("");

  const sortOptions = [
    { label: "Todos", value: "" },
    ...(status?.map((item: any) => {
      return { label: item?.name, value: item?.id };
    }) || []),
  ];

  useEffect(() => {
    setStatusSelect("Analise");
    setDataViewValue(subzone);
    setGlobalFilterValue("");
    setFilteredValue(subzone?.filter((subzona: any) => subzona));
  }, [subzone, setStatusSelect]);

  const onFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setGlobalFilterValue(value);
    if (value.length === 0) {
      setFilteredValue(null);
    } else {
      const filtered = dataViewValue?.filter((product) => {
        const productNameLowercase = product.name.toLowerCase();
        const searchValueLowercase = value.toLowerCase();
        return productNameLowercase.includes(searchValueLowercase);
      });

      setFilteredValue(filtered);
    }
  };

  const [filterStatus, setFilterStatus] = useState("Analise");

  // Atualizando a função de filtro para aceitar um status padrão
  const onFilterByStatus = (status: string) => {
    let filtered = [];
    if (status === "") {
      filtered = subzone;
    } else {
      filtered = subzone?.filter((subzona: any) => subzona.status === status);
    }
    setFilteredValue(filtered);
    setFilterStatus(status);
    setStatusSelect(status);
  };

  //estilização da cor do status
  const colorStatus = (data: any) => {
    if (data.status === 1) {
      return 'orange'
    }else if (data.status === 2) {
      return 'green'
    }else{
      return 'red'
    }
  }

  // Formatar titulo
  const NameFormat = (name: any) => {
    if (name.length > 30) {
      return name.substring(0, 20) + "...";
    }
    return name;
  };

  // Formatar Descrição
  const DescriptionFormat = (description: any) => {
    if (description.length > 100) {
      return description.substring(0,100) + '...'
    }

    return description
  }

  // Componente Header
  const dataViewHeader = (
    <div className="flex flex-column md:flex-row md:justify-content-between gap-2">
      {adminorowner && (
        <Dropdown
          value={filterStatus}
          options={sortOptions}
          optionLabel="label"
          placeholder="Escolha um status"
          onChange={(e) => onFilterByStatus(e.value)}
        />
      )}
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          value={globalFilterValue}
          onChange={onFilter}
          placeholder="Pesquise pelo nome"
          style={{ width: '100%' }}
        />
      </span>
      <DataViewLayoutOptions
        layout={layout}
        onChange={(e: any) => setLayout(e.value)}
      />
    </div>
  );

  //Componente de visualização em Lista

  const dataviewListItem = (data: any) => {
    return (
      <div
        className="col-12"
        onClick={() => {
          if (data.status === 2) {
            history(`/zona/${id}/subzona/${data?.id}/painel`);
          }
          if (data.status === 3 && !adminorowner) {
            Swal.fire("Subzona Reprovada");
          }
          if (data.status === 1 && !adminorowner) {
            Swal.fire("Aguarde Análise");
          }
        }}
        style={{ cursor: data.status === 2 ? "pointer" : "" }}
      >
        <div  style={{ gap: "16px" }} className="flex flex-column md:flex-row align-items-center  p-3 w-full">
          {/* <img
            src={data.aws.url}
            alt={data.name}
            className="my-4 md:my-0 w-8 md:w-8rem shadow-2 mr-5"
          /> */}
          <Image
            className="shadow-2"
            src={data.aws.url}
            alt={data.name}
            height="120"
            width="120"
          />
          <AreaHeaderList className="flex flex-column ">
            <Tooltip title={data.name}>
            <div className="font-bold text-xl">{NameFormat(data.name)}</div>
            </Tooltip>

              <div className="flex align-items-center">
                <p>Criado por: </p>
                <span className="font-semibold ml-2">
                  {data.user_owner.username}
                </span>
              </div>
            <div className="flex align-items-center">
              <p>Status: </p>
              <span className="font-semibold font-italic ml-2" style={{ color: colorStatus(data) }}>
                {data.status === 1
                  ? "Análise"
                  : data.status === 2
                  ? "Aceito"
                  : "Não Aceito"}
              </span>
            </div>
          </AreaHeaderList>
          <AreaDescriptionList className="flex-1 flex flex-column text-justify md:text-justify">
            <div className="text-left font-italic text-md font-bold">
              Descrição:
            </div>
            {data.description ? (
             <ViewHTML style={{ width: '95%' }} item={data.description}/>
            ) : (
              <p>Sem descrição</p>
            )}
            {/* <Rating value={data.rating} readOnly cancel={false} className="mb-2"></Rating> */}
          </AreaDescriptionList>
          {data.archives && adminorowner && data.status === 1 && (
            <div style={{margin: '0px 8px 0px 0px', textAlign: "center" }}>
              <h5>Arquivo</h5>
              <>
                {data.archives.map((item: any) => {
                  return (
                    <>
                      <Padding />
                      <Arq
                        onClick={() => {
                          window.open(`${item.aws.url}`);
                        }}
                      >
                        <Row>
                          <i className="pi pi-file"></i>
                          <p>arquivo</p>
                        </Row>
                      </Arq>
                    </>
                  );
                })}
              </>
            </div>
          )}
          <div className="flex flex-row md:flex-column justify-content-between w-full md:w-auto align-items-center md:align-items-end mt-5 md:mt-0">
            {adminorowner ? (
              <>
                {data.status === 1 ? (
                  <>
                    <Button
                      icon="pi pi-check"
                      style={{ marginBottom: "8px" }}
                      tooltip="Aprovar"
                      onClick={() => handleAvaliate(data.id, 2)}
                    />
                    <Button
                      icon="pi pi-times"
                      severity="danger"
                      tooltip="Reprovar"
                      onClick={() => handleAvaliate(data.id, 3)}
                    />
                  </>
                ) : data.status === 3 ? (
                  <>
                    <Button
                      icon="pi pi-trash"
                      severity="danger"
                      tooltip="Excluir"
                      onClick={() => {const idsubzona: any = data.id; setVisible(idsubzona.toString());}}
                    />
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  // Componente de visualização em GRID

  const dataviewGridItem = (data: any) => {
    return (
      <div
        className="col-12 lg:col-4"
        onClick={() => {
          if (data.status === 2) {
            history(`/zona/${id}/subzona/${data?.id}/painel`);
          }
          if (data.status === 3 && !adminorowner) {
            Swal.fire("Subzona Reprovada");
          }
          if (data.status === 1 && !adminorowner) {
            Swal.fire("Aguarde Análise");
          }
        }}
       
      >
        <CardGrid className="card m-3 border-1 surface-border">
          <div className="flex flex-wrap gap-2 align-items-center justify-content-between mb-2">
            <div className="flex align-items-center mb-2">
              <p>Criado por: </p>
              <span className="font-semibold ml-2">
                {data.user_owner.username}
              </span>
            </div>
          </div>
          <div className="flex flex-column align-items-center text-justify mb-3">
            {/* <img
              src={data.aws.url}
              alt={data.name}
              className="w-8 shadow-2 my-3 mx-0"
            /> */}
            <Image
              className="shadow-2"
              src={data.aws.url}
              alt={data.name}
              height="120"
              width="120"
            />
            <Padding padding="6px" />
            <div className="text-xl font-bold">{NameFormat(data.name)}</div>
            <Padding padding="6px" />
            {data.description ? (
             <ViewHTML tooltip="Ver + detalhes no modo lista" item={DescriptionFormat(data.description)}/>
            ) : (
              <p>Sem descrição</p>
            )}

            {/* <Rating value={data.rating} readOnly cancel={false} /> */}
          </div>
          <div className="flex mb-3">
          <p>Status: </p>
              <span className="font-semibold font-italic ml-2" style={{ color: colorStatus(data) }}>
                {data.status === 1
                  ? "Análise"
                  : data.status === 2
                  ? "Aceito"
                  : "Não Aceito"}
              </span>
          </div>
          {data.archives && adminorowner && data.status === 1 && (
            <>
              <h5>Arquivo</h5>
              <>
                {data.archives.map((item: any) => {
                  return (
                    <>
                      <Padding />
                      <Arq
                        onClick={() => {
                          window.open(`${item.aws.url}`);
                        }}
                      >
                        <Row>
                          <i className="pi pi-file"></i>
                          <p>arquivo</p>
                        </Row>
                      </Arq>
                    </>
                  );
                })}
              </>
            </>
          )}
          <div
            className="flex"
            style={{ alignItems: "flex-end", justifyContent: "flex-end" }}
          >
            {adminorowner ? (
              <>
                {data.status === 1 ? (
                  <>
                    <Button
                      icon="pi pi-check"
                      style={{ marginRight: "8px" }}
                      tooltip="Aprovar"
                      onClick={() => handleAvaliate(data.id, 2)}
                    />
                    <Button
                      icon="pi pi-times"
                      severity="danger"
                      tooltip="Reprovar"
                      onClick={() => handleAvaliate(data.id, 3)}
                    />
                  </>
                ) : data.status === 3 ? (
                  <>
                    <Button
                      icon="pi pi-trash"
                      severity="danger"
                      style={{ justifySelf: "flex-end !!important" }}
                      tooltip="Excluir"
                      onClick={() => {const idsubzona: any = data.id; setVisible(idsubzona.toString());}}
                    />
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </CardGrid>
      </div>
    );
  };

  const itemTemplate = (
    data: any,
    layout: "grid" | "list" | (string & Record<string, unknown>)
  ) => {
    if (!data) {
      return;
    }

    if (layout === "list") {
      return dataviewListItem(data);
    } else if (layout === "grid") {
      return dataviewGridItem(data);
    }
  };


  return (
    <div className="grid p-3">
      <Toast ref={toast} />
      <div className="col-12 p-1 md:p-4 ">
        <div className="card">
          <h4>Status Subzonas</h4>
          {subzone ? (
            <DataView
              value={filteredValue || dataViewValue}
              layout={layout}
              paginator
              rows={9}
              sortField={sortField}
              itemTemplate={itemTemplate}
              header={dataViewHeader}
            ></DataView>
          ) : (
            <SkeletonSubzona />
          )}
          <ConfirmationDialog
            accept={() => {
              deleteSubzona(visible);
            }}
            visible={visible}
            reject={() => setVisible(true)}
            message="Deseja Excluir essa Subzona?"
            onHide={() => setVisible(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default DataViewSubzone;
